// This file is generated by create-new-theme.js script
import DefaultTheme from './DefaultTheme';
import DarkTheme from './DarkTheme';
import NordTheme from './NordTheme';
import SolarizedDarkTheme from './SolarizedDarkTheme';
import OneDarkTheme from './OneDarkTheme';

/*eslint no-useless-computed-key: "off"*/
export const themes = {
  ['GDevelop default']: DefaultTheme,
  ['Dark']: DarkTheme,
  ['Nord']: NordTheme,
  ['Solarized Dark']: SolarizedDarkTheme,
  ['One Dark']: OneDarkTheme,
};
